import { Component, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: [ './privacy.component.css' ],
  animations: [
    trigger('show', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('800ms ease-in-out', style({ opacity: 1 }))
        ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('800ms ease-in-out', style({ opacity: 0 }))
        ])
    ])
  ]
})

export class PrivacyComponent {

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PrivacyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
