import * as SharedActions from './shared.actions';
import {SharedActionTypes} from './shared.actions';

export interface State {
  lastRoute: string;
  redirectRoute: string;
  redirectDialog: string;
}

export const initialState: State = {
  lastRoute: '/',
  redirectRoute: '/',
  redirectDialog: '',
};

export function reducer(
  state = initialState,
  action: SharedActions.SharedActionsUnion
): State {
  switch (action.type) {
    case SharedActionTypes.SetLastRoute: {
      return {
        ...state,
        lastRoute: action.payload.route
      };
    }

    case SharedActionTypes.SetRedirectRoute: {
      return {
        ...state,
        redirectRoute: action.payload.route
      };
    }

    case SharedActionTypes.SetRedirectDialog: {
      return {
        ...state,
        redirectDialog: action.payload.route
      };
    }

    default: {
      return state;
    }
  }
}

export const getLastRoute = (state: State) => state.lastRoute;
export const getRedirectRoute = (state: State) => state.redirectRoute;
export const getRedirectState = (state: State) => state;
