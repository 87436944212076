import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../state-management';
import {first, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoutingHelperService {
  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
  ) {
  }

  /**
   * For the first navigation - go to main page
   * Otherwise go back
   */
  public getLastPath(): Observable<string> {
    return this.store.pipe(
      select(fromRoot.isFirstRouterNavigation),
      switchMap(isFirst => this.store.pipe(select(fromRoot.getLastRoute)), (isFirst, route) => ({isFirst, route})),
      switchMap(data => data.isFirst ? '/' : data.route),
      first(),
    );
  }
}
