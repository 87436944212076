import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Title} from '@angular/platform-browser';
import {Animations} from '../../tools/animations';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-error-404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.css'],
  animations: [Animations]
})

export class Error404Component implements OnInit {

  subloader = false;

  constructor(
    private router: Router,
    private titleService: Title,
    private _location: Location
  ) {
    this.titleService.setTitle('Not Found | Australia Wealth');
  }

  ngOnInit() {
  }

  backStep() {
    if (!document.referrer.includes(window.location.hostname) ) {
      this.router.navigate(['/']);
    } else {
      this._location.back();
    }
  }

}
