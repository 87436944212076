import {
  ActionReducerMap,
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store';
import {environment} from '../../environments/environment';
import * as fromRouter from '@ngrx/router-store';
// import * as fromlosingincome from './losing-income/data.reducer';
// import * as fromhiddenincomeproperty from './hidden-income-property/data.reducer';
// import * as fromretirement from './retirement/data.reducer';
// import * as frompropertymistakes from './propertymistakes/data.reducer';
// import * as fromhomebuilder from './homebuilder/data.reducer';
// import * as fromsdaproperty from './sdaproperty/data.reducer';
// import * as fromwealthguide from './wealthguide/data.reducer';
// import * as fromopportunities from './opportunities/data.reducer';
// import * as fromreadiness from './readiness/data.reducer';
import * as fromShared from './shared/shared.reducer';

/**
 * storeFreeze prevents affordability from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the affordability.
 */
import {storeFreeze} from 'ngrx-store-freeze';

import {localStorageSync} from 'ngrx-store-localstorage';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the affordability of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

// import * as fromLayout from '@example-app/core/reducers/layout.reducer';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level affordability interface is just a map of keys to inner affordability types.
 */
export interface State {
  // layout: fromLayout.State;
  router: fromRouter.RouterReducerState;
  // losingincome: fromlosingincome.DataState;
  // hiddenincomeproperty: fromhiddenincomeproperty.DataState;
  // retirement: fromretirement.DataState;
  // propertymistakes: frompropertymistakes.DataState;
  // homebuilder: fromhomebuilder.DataState;
  // sdaproperty: fromsdaproperty.DataState;
  // wealthguide: fromwealthguide.DataState;
  // opportunities: fromopportunities.DataState;
  // readiness: fromreadiness.DataState;
  shared: fromShared.State;
}

/**
 * Our affordability is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial affordability and return a new immutable affordability.
 */
export const reducers: ActionReducerMap<State> = {
  // layout: fromLayout.reducer,
  router: fromRouter.routerReducer,
  // losingincome: fromlosingincome.dataReducer,
  // hiddenincomeproperty: fromhiddenincomeproperty.dataReducer,
  // retirement: fromretirement.dataReducer,
  // propertymistakes: frompropertymistakes.dataReducer,
  // homebuilder: fromhomebuilder.dataReducer,
  // sdaproperty: fromsdaproperty.dataReducer,
  // wealthguide: fromwealthguide.dataReducer,
  // opportunities: fromopportunities.dataReducer,
  // readiness: fromreadiness.dataReducer,
  shared: fromShared.reducer,
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: any): any => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    // console.log('prev state', state);
    // console.log('action', action);
    // console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['data', 'losingincome', 'hiddenincomeproperty', 'retirement', 'sdaproperty', 'propertymistakes', 'homebuilder', 'wealthguide', 'opportunities', 'readiness'],
    rehydrate: true
  })(reducer);
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  // ? [logger, storeFreeze]
  // : [];
  // ? [logger, localStorageSyncReducer, storeFreeze] // TODO: recheck and re-enable storeFreeze.
  ? [logger, localStorageSyncReducer]
  : [localStorageSyncReducer];

/**
 * Router selectors
 */

export const getRouterState = createFeatureSelector<State, fromRouter.RouterReducerState>('router');

export const getRouterNavigationId = createSelector(
  getRouterState,
  state => state.navigationId
);

export const isFirstRouterNavigation = createSelector(
  getRouterNavigationId,
  id => id === 1
);

/**
 * Shared selectors
 */

export const getSharedState = createFeatureSelector<State, fromShared.State>('shared');

export const getLastRoute = createSelector(
  getSharedState,
  fromShared.getLastRoute
);

export const getRedirectRoute = createSelector(
  getSharedState,
  fromShared.getRedirectRoute
);

export const getRedirectState = createSelector(
  getSharedState,
  fromShared.getRedirectState
);
