import { trigger, state, style, transition, animate, group, query, stagger, keyframes, animateChild, AnimationMetadata } from '@angular/animations';

export const Animations = [
    trigger('slideInOut', [
        transition(':enter', [
            style({transform: 'translateY(-100%)', opacity: 0}),
            animate('200ms ease-in', style({transform: 'translateY(0%)', opacity: 1}))
        ]),
         transition(':leave', [
            animate('200ms ease-in', style({transform: 'translateY(-100%)', opacity: 0}))
        ])
    ]),

    trigger('show', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('500ms ease-in-out', style({ opacity: 1 }))
        ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('500ms ease-in-out', style({ opacity: 0 }))
        ])
    ]),

    trigger('fadeIn', [
        transition(':enter', [
            style({ opacity: 0, left: 0, position: 'relative' }),
            animate('500ms ease-in-out', style({ opacity: 1 }))
        ]),
        transition(':leave', [
            style({ opacity: 1, position: 'absolute' }),
            animate('500ms ease-in-out', style({ opacity: 0 }))
        ])
    ]),

    trigger('stagger', [
        transition('* => *', [
            query(':enter', style({ opacity: 0 }), {optional: true}),
            query(':enter', stagger('300ms', [
            animate('.75s 0.5s ease-in', keyframes([
                style({opacity: 0, transform: 'scale3d(.3, .3, .3)', offset: 0}),
                style({transform: 'scale3d(1.1, 1.1, 1.1)', offset: 0.2}),
                style({transform: 'scale3d(.9, .9, .9)', offset: 0.4}),
                style({opacity: 0.8, transform: 'scale3d(1.03, 1.03, 1.03)', offset: 0.6}),
                style({transform: 'scale3d(.97, .97, .97)', offset: 0.8}),
                style({opacity: 1, transform: 'scale3d(1, 1, 1)', offset: 1.0}),
            ]))]), {optional: true})
        ])
    ]),

    trigger('slideDownUp', [
      state('1', style({
          'max-height': 'initial', 'opacity': '1', 'visibility': 'visible'
      })),
      state('0', style({
          'max-height': '0px', 'opacity': '0', 'visibility': 'hidden'
      })),
      transition('1 => 0', [group([
          animate('1200ms ease-in-out', style({
              'opacity': '0'
          })),
          animate('600ms ease-in-out', style({
              'max-height': '0px'
          })),
          animate('700ms ease-in-out', style({
              'visibility': 'hidden'
          }))
      ]
      )]),
      transition('0 => 1', [group([
          animate('1222ms ease-in-out', style({
              'visibility': 'visible'
          })),
          animate('600ms ease-in-out', style({
              'max-height': 'initial'
          })),
          animate('800ms ease-in-out', style({
              'opacity': '1'
          }))
      ]
      )])
  ]),

    trigger('listStagger', [
        transition('* <=> *', [
          query( ':enter',
            [
              style({ opacity: 0, transform: 'translateY(-15px)' }),
              stagger( '300ms', animate( '550ms ease-out', style({ opacity: 1, transform: 'translateY(0px)' }) ) )
            ],
            { optional: true }
          ),
          query(':leave', animate('50ms', style({ opacity: 0 })),
            { optional: true }
          )
        ])
    ]),

    trigger('nested', [
        transition('* <=> *', [
          query('@*', [
            animateChild(),
          ])
        ])
    ]),

];

export const routerTransition = trigger('routerTransition', [
    transition('* => main', [
      query(':enter, :leave', style({ position: 'absolute', width: '100%', height: '100%' })
        , { optional: true }),
      group([
        query(':enter', [
          style({ transform: 'translateX(-100%)' }),
          animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
        ], { optional: true }),
        query(':leave', [
          style({ transform: 'translateX(0%)' }),
          animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' }))
        ], { optional: true }),
      ])
    ]),
    transition('* => sub', [
      group([
        query(':enter, :leave', style({ position: 'absolute', width: '100%', height: '100%' })
        , { optional: true }),
        query(':enter', [
          style({ transform: 'translateX(100%)' }),
          animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
        ], { optional: true }),
        query(':leave', [
          style({ transform: 'translateX(0%)' }),
          animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
        ], { optional: true }),
      ])
    ])
  ]);

export const fadeIn: AnimationMetadata[] = [
  style({ opacity: 0 }),
  animate('1000ms', style({ opacity: 1 })),
];

export const fadeInDown: AnimationMetadata[] = [
  style({ opacity: 0, transform: 'translate3d(0, -20%, 0)' }),
  animate('500ms', style({ opacity: 1, transform: 'translate3d(0, 0, 0)' })),
];

export const fadeInUp: AnimationMetadata[] = [
  style({ opacity: 0, transform: 'translate3d(0, 20%, 0)' }),
  animate('500ms', style({ opacity: 1, transform: 'translate3d(0, 0, 0)' })),
];

export const fadeInLeft: AnimationMetadata[] = [
  style({ opacity: 0, transform: 'translate3d(-10%, 0, 0)' }),
  animate('500ms', style({ opacity: 1, transform: 'translate3d(0, 0, 0)' })),
];

export const fadeInRight: AnimationMetadata[] = [
  style({ opacity: 0, transform: 'translate3d(10%, 0, 0)' }),
  animate('500ms', style({ opacity: 1, transform: 'translate3d(0, 0, 0)' })),
];
