import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// Services

// Home
import {HomeComponent} from './components/home.component';

const routes: Routes = [
  // Home
  {path: '', component: HomeComponent, resolve: {}}
];

@NgModule({
  imports: [
    RouterModule.forChild(
      routes
    ),
  ],
  providers: [
  ],
  exports: [
    RouterModule
  ]
})
export class HomeRoutingModule {
}
