import {Component, Input, OnInit} from '@angular/core';
import {TermsComponent} from '../../../legal/terms/terms.component';

@Component({
  selector: 'app-terms-popup',
  templateUrl: './terms-popup.component.html',
  styleUrls: ['./terms-popup.component.css']
})
export class TermsPopupComponent implements OnInit {
  @Input() text = 'Terms';
  @Input() customTerms;

  constructor() {
  }

  ngOnInit() {
    if (this.customTerms == undefined) {
      this.customTerms = TermsComponent;
    }
  }
}
