import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/portal';

@Component({
  selector: 'app-configurable-popup',
  templateUrl: './configurable-popup.component.html',
  styleUrls: ['./configurable-popup.component.css']
})
export class ConfigurablePopupComponent {
  @Input() text: string;
  @Input() component: ComponentType<any>;

  constructor(
    public dialog: MatDialog
  ) {
  }

  openPopup(): void {
    const dialogRef = this.dialog.open(this.component, {
      width: '820px',
      autoFocus: true,
      disableClose: false,
      closeOnNavigation: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
