import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

import {Api} from './api';
import {HttpErrorHandler, HandleError} from '../http-error-handler.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'lgc-auth-token',
  })
};

const jsonhttpOptions = {
  headers: new HttpHeaders({
  })
};

@Injectable()
export class ApiService {
  statsUrl;
  syncUrl;
  error: any;
  headers: string[];
  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('ApiService');
  }

  saveDetails(data: any): Observable<any> {
    // this.getConfig();
    return this.http.post<any>('https://australiawealth.com.au/aw-db/process.php', { data: data }, httpOptions)
      .pipe(
        switchMap(resp => {
          return of(resp);
        }),
        catchError(this.handleError('saveDetails', data))
      );
  }

  updateDetails(data: any): Observable<any> {
    // this.getConfig();
    return this.http.post<any>('https://australiawealth.com.au/aw-db/process.php', { data: data }, httpOptions)
      .pipe(
        switchMap(resp => {
          return of(resp);
        }),
        catchError((error) => {
          console.log(error);
          return this.handleError('updateDetails', data);
        })
      );
  }

  /** GET Campaigns */
  getOffers(offer_request: any): Observable<any[]> {
    // this.getConfig();
    return this.http.post<any[]>('https://app.leadsbasis.com/endpoint/offers', offer_request, httpOptions)
      .pipe(
        catchError(this.handleError('getOffers', []))
      );
  }

  /** POST: sync with leadsbasis */
  directprocessLead(user: Api): Observable<Api> {
    // this.getConfig();
    return this.http.post<Api>('https://app.leadsbasis.com/endpoint/process', user, httpOptions)
      .pipe(
        catchError(this.handleError('directprocessLead', user))
      );
  }

  /** Facebook Conversions */
  facebookConversions(data: any): Observable<any> {
    // this.getConfig();
    return this.http.post<any>('https://australiawealth.com.au/aw-db/fbConversions.php', { data: data }, httpOptions)
      .pipe(
        switchMap(resp => {
          return of(resp);
        }),
        catchError((error) => {
          console.log(error);
          return this.handleError('updateDetails', data);
        })
      );
  }

  /**
   * Function to extract the data when the server return some
   *
   * @param res
   */
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  /**
   * Function to GET what you want
   *
   * @param url
   */
  public getjsonData(url: string): Observable<any> {
    // Call the http GET
    return this.http.get(url, jsonhttpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }
}
