import {Component, Input, OnInit} from '@angular/core';
import {PrivacyComponent} from '../../../legal/privacy/privacy.component';

@Component({
  selector: 'app-privacy-popup',
  templateUrl: './privacy-popup.component.html',
  styleUrls: ['./privacy-popup.component.css']
})
export class PrivacyPopupComponent implements OnInit {
  @Input() text = 'Privacy';
  @Input() customPrivacy;

  constructor() {
  }

  ngOnInit() {
    if (this.customPrivacy == undefined) {
      this.customPrivacy = PrivacyComponent;
    }
  }
}
