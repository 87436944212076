import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';

@Injectable()
export class RouteGuard implements CanActivate {

  constructor(
    protected router: Router,
  ) {
  }

  canActivate(): Observable<boolean> {
    return of(true);
    /*
    this.localStorage.getItem('user').subscribe((user) => {
        if (user.data.length > 0) {
            return of(true);
        } else {
            this.router.navigate(['/']);
        }
    });
    return of(false);
    */
  }

}
