import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {RouteGuard} from './app-guard.guard';

// Error
import {Error404Component} from './pages/error/error404.component';

const appRoutes: Routes = [

  // General & Content
  // Home
  {
    path: '',
    loadChildren: () => import('./pages/home/home-feature.module').then(m => m.HomeFeatureModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about-feature.module').then(m => m.AboutFeatureModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact-feature.module').then(m => m.ContactFeatureModule)
  },

  // losing-income
  {
    path: 'losing-income',
    loadChildren: () => import('./pages/losing-income/losing-income-feature.module').then(m => m.LosingincomeFeatureModule)
  },

  // hidden-income-property
  {
    path: 'hidden-income-property',
    loadChildren: () => import('./pages/hidden-income-property/hidden-income-property-feature.module')
    .then(m => m.HiddenIncomePropertyFeatureModule)
  },

  // Retirement
  {
    path: 'retirement',
    loadChildren: () => import('./pages/retirement/retirement-feature.module').then(m => m.RetirementFeatureModule)
  },

  // SDA NDIS Investing
  {
    path: 'sda-ndis-investing',
    loadChildren: () => import('./pages/sda-property/sdaproperty-feature.module').then(m => m.SdaPropertyFeatureModule)
  },

  // Property Mistakes
  {
    path: 'property-mistakes',
    loadChildren: () => import('./pages/propertymistakes/propertymistakes-feature.module').then(m => m.PropertymistakesFeatureModule)
  },

  // Wealth Guide
  {
    path: 'guide',
    loadChildren: () => import('./pages/wealthguide/wealthguide-feature.module').then(m => m.WealthGuideFeatureModule)
  },

  // Homebuilder
  {
    path: 'homebuilder',
    loadChildren: () => import('./pages/homebuilder/homebuilder-feature.module').then(m => m.HomebuilderFeatureModule)
  },

  // Interest Savings
  // {
  //   path: 'interest-savings',
  //   loadChildren: () => import('./pages/interestsavings/interestsavings-feature.module').then(m => m.InterestsavingsFeatureModule)
  // },

  // Readiness
  {
    path: 'readiness',
    loadChildren: () => import('./pages/readiness/readiness-feature.module').then(m => m.ReadinessFeatureModule)
  },

  // // Refinance
  // {
  //   path: 'refinance',
  //   loadChildren: () => import('./pages/refinance/refinance-feature.module').then(m => m.RefinanceFeatureModule)
  // },

  // Opportunities
  {
    path: 'opportunities',
    loadChildren: () => import('./pages/opportunities/opportunities-feature.module').then(m => m.OpportunitiesFeatureModule)
  },

  // Investor
  {
    path: 'investor',
    loadChildren: () => import('./pages/investor/investor-feature.module').then(m => m.InvestorFeatureModule)
  },

  // Affordability
  // {
  //   path: 'affordability',
  //   loadChildren: () => import('./pages/affordability/affordability-feature.module').then(m => m.AffordabilityFeatureModule)
  // },
  // Redirect due to outdated code
  {
    path: 'affordability',
    loadChildren: () => import('./pages/losing-income/losing-income-feature.module').then(m => m.LosingincomeFeatureModule)
  },


  // Redirects
  {path: 'loansavings', redirectTo: '/guide/loansavings'},
  {path: 'retireplan', redirectTo: '/guide/retireplan'},
  {path: 'comfortable', redirectTo: '/guide/comfortcost'},
  {path: 'fi', redirectTo: '/guide/financial-independence-calculator'},
  {path: 'ota', redirectTo: '/guide/optimise-tax/appointment'},
  {path: 'phs', redirectTo: '/guide/pay-off-home/appointment'},
  {path: 'clf', redirectTo: '/guide/comfortable-life/appointment'},
  {path: 'pin', redirectTo: '/guide/property-strategy/appointment'},
  {path: 'rpl', redirectTo: '/guide/retirement-plan/appointment'},
  {path: 'wpi', redirectTo: '/guide/why-property-investing/appointment'},
  {path: 'fire', redirectTo: '/guide/financial-independence/appointment'},

  // Handle 404s
  {path: '**', component: Error404Component}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(
      appRoutes,
      {
        // preloadingStrategy: PreloadAllModules
      }
    ),
  ],
  providers: [
    RouteGuard
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
