import {Component, Input, OnInit} from '@angular/core';
import {Animations} from '../tools/animations';

// import {AngularFireFunctions} from '@angular/fire/functions';

import {ApiService} from '../api/api.service';

@Component({
  selector: 'app-template-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  animations: [Animations]
})

export class FooterComponent implements OnInit {
  @Input() customTerms: Component;
  @Input() customPrivacy: Component;
  poweredby: string;
  subloader = false;
  loggedin = false;
  date = new Date();
  fullYear = this.date.getFullYear();

  constructor(
    protected apiService: ApiService,
  ) {
  }

  ngOnInit() {
  }

}
