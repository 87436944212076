import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatRippleModule, MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule, } from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';

import {PlatformModule} from '@angular/cdk/platform';
import {ObserversModule} from '@angular/cdk/observers';
import {DomSanitizer} from '@angular/platform-browser';

/**
 * NgModule that includes all Material modules that are required
 */
@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    MatSliderModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    MatRippleModule,
    ObserversModule,
    PlatformModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatProgressBarModule,
    MatIconModule,
    MatFormFieldModule,
    MatTabsModule,
    MatExpansionModule,
    MatListModule,
    MatButtonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatTooltipModule
  ]
})
export class CustomMaterialModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(`ic_house`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_house.svg'));
    this.matIconRegistry.addSvgIcon(`ic_chevron_left`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_chevron_left.svg'));
    this.matIconRegistry.addSvgIcon(`ic_chevron_right`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_chevron_right.svg'));
    this.matIconRegistry.addSvgIcon(`ic_mail`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_mail.svg'));
    this.matIconRegistry.addSvgIcon(`ic_menu`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_menu.svg'));
    this.matIconRegistry.addSvgIcon(`ic_piggybank`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_piggybank.svg'));
    this.matIconRegistry.addSvgIcon(`ic_settings`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_settings.svg'));
    this.matIconRegistry.addSvgIcon(`ic_assignment`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_assignment.svg'));
    this.matIconRegistry.addSvgIcon(`ic_monetization_on`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_monetization_on.svg'));
    this.matIconRegistry.addSvgIcon(`ic_info_outline`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_info_outline.svg'));
    this.matIconRegistry.addSvgIcon(`ic_priority`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_priority.svg'));
    this.matIconRegistry.addSvgIcon(`ic_info`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_info.svg'));
    this.matIconRegistry.addSvgIcon(`ic_home`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_home.svg'));
    this.matIconRegistry.addSvgIcon(`ic_help_outline`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_help_outline.svg'));
    this.matIconRegistry.addSvgIcon(`ic_tick`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_tick.svg'));
    this.matIconRegistry.addSvgIcon(`ic_check`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_check.svg'));
    this.matIconRegistry.addSvgIcon(`ic_check_2`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_check_2.svg'));
    this.matIconRegistry.addSvgIcon(`ic_dollar`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_dollar.svg'));
    this.matIconRegistry.addSvgIcon(`ic_facebook`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_facebook.svg'));
    this.matIconRegistry.addSvgIcon(`ic_google`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_google.svg'));
    this.matIconRegistry.addSvgIcon(`ic_twitter`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_twitter.svg'));
    this.matIconRegistry.addSvgIcon(`ic_instagram`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_instagram.svg'));
    this.matIconRegistry.addSvgIcon(`ic_email`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_email.svg'));
    this.matIconRegistry.addSvgIcon(`ic_email_2`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_email_2.svg'));
    this.matIconRegistry.addSvgIcon(`ic_email_3`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_email_3.svg'));
    this.matIconRegistry.addSvgIcon(`ic_phone`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_phone.svg'));
    this.matIconRegistry.addSvgIcon(`ic_phone_2`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_phone_2.svg'));
    this.matIconRegistry.addSvgIcon(`ic_password`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_password.svg'));
    this.matIconRegistry.addSvgIcon(`ic_location`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_location.svg'));
    this.matIconRegistry.addSvgIcon(`ic_name`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_name.svg'));
    this.matIconRegistry.addSvgIcon(`ic_person`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_person.svg'));
    this.matIconRegistry.addSvgIcon(`ic_airline`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_airline.svg'));
    this.matIconRegistry.addSvgIcon(`ic_deposit`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_deposit.svg'));
    this.matIconRegistry.addSvgIcon(`ic_trash`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_trash.svg'));
    this.matIconRegistry.addSvgIcon(`ic_close`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_close.svg'));
    this.matIconRegistry.addSvgIcon(`ic_ring`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_ring.svg'));
    this.matIconRegistry.addSvgIcon(`ic_edit`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_edit.svg'));
    this.matIconRegistry.addSvgIcon(`ic_save`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_save.svg'));
    this.matIconRegistry.addSvgIcon(`ic_save_2`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_save_2.svg'));
    this.matIconRegistry.addSvgIcon(`ic_new`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_new.svg'));
    this.matIconRegistry.addSvgIcon(`ic_user`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_user.svg'));
    this.matIconRegistry.addSvgIcon(`ic_money`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_money.svg'));
    this.matIconRegistry.addSvgIcon(`ic_mailnotify`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_mailnotify.svg'));
    this.matIconRegistry.addSvgIcon(`ic_new_releases`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_new_releases.svg'));
    this.matIconRegistry.addSvgIcon(`ic_arrowright`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_arrowright.svg'));
    this.matIconRegistry.addSvgIcon(`ic_arrow_down`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_arrow_down.svg'));
    this.matIconRegistry.addSvgIcon(`ic_arrow_up`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_arrow_up.svg'));
    this.matIconRegistry.addSvgIcon(`ic_plus`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_plus.svg'));
    this.matIconRegistry.addSvgIcon(`ic_minus`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_minus.svg'));
    this.matIconRegistry.addSvgIcon(`ic_chat`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_chat.svg'));
    this.matIconRegistry.addSvgIcon(`ic_chat_2`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_chat_2.svg'));
    this.matIconRegistry.addSvgIcon(`curve`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/curve.svg'));
    this.matIconRegistry.addSvgIcon(`income_tax`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/income_tax.svg'));
    this.matIconRegistry.addSvgIcon(`home_loan`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/home_loan.svg'));
    this.matIconRegistry.addSvgIcon(`net_income`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/net_income.svg'));
    this.matIconRegistry.addSvgIcon(`ic_play`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_play.svg'));
    this.matIconRegistry.addSvgIcon(`ic_heart`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_heart.svg'));
    this.matIconRegistry.addSvgIcon(`ic_lock`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_lock.svg'));
    this.matIconRegistry.addSvgIcon(`ic_time`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_time.svg'));
    this.matIconRegistry.addSvgIcon(`ic_calendar`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_calendar.svg'));
    this.matIconRegistry.addSvgIcon(`ic_event`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_event.svg'));
    this.matIconRegistry.addSvgIcon(`ic_contacts`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_contacts.svg'));
    this.matIconRegistry.addSvgIcon(`ic_star`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_star.svg'));
    this.matIconRegistry.addSvgIcon(`ic_download`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_download.svg'));
    this.matIconRegistry.addSvgIcon(`ic_pdf`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_pdf.svg'));
    this.matIconRegistry.addSvgIcon(`ic_zip`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_zip.svg'));

    this.matIconRegistry.addSvgIcon(`ic_bed`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_bed.svg'));
    this.matIconRegistry.addSvgIcon(`ic_car`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_car.svg'));
    this.matIconRegistry.addSvgIcon(`ic_bath`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_bath.svg'));
    this.matIconRegistry.addSvgIcon(`ic_bank`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_bank.svg'));
    this.matIconRegistry.addSvgIcon(`ic_dollar`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_dollar.svg'));
    this.matIconRegistry.addSvgIcon(`ic_suitcase`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ic_suitcase.svg'));

    this.matIconRegistry.addSvgIcon(`retirement-planning`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/retirement/retirement-planning.svg'));
    this.matIconRegistry.addSvgIcon(`propertymistakes-planning`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/propertymistakes/propertymistakes-planning.svg'));
    this.matIconRegistry.addSvgIcon(`MortgageRatesChart`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/investor/offers/finance/MortgageRatesChart.svg'));
    this.matIconRegistry.addSvgIcon(`dollarTag`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/investor/offers/finance/dollar-tag.svg'));
    this.matIconRegistry.addSvgIcon(`searchTag`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/investor/offers/finance/search-tag.svg'));
    this.matIconRegistry.addSvgIcon(`rateTag`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/investor/offers/finance/rate-tag.svg'));
  }
}
