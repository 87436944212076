import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrivacyPopupComponent} from './components/privacy-popup/privacy-popup.component';
import {TermsPopupComponent} from './components/terms-popup/terms-popup.component';
import {ConfigurablePopupComponent} from './components/configurable-popup/configurable-popup.component';
import {UuidService} from './services/uuid.service';
import {TruncatePipe} from '../shared/pipes/truncate.pipe';

@NgModule({
  declarations: [
    PrivacyPopupComponent,
    TermsPopupComponent,
    ConfigurablePopupComponent,
    TruncatePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PrivacyPopupComponent,
    TermsPopupComponent,
    TruncatePipe
  ],
  providers: [
    UuidService
  ]
})
export class SharedModule {
}
