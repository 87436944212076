import {Injectable} from '@angular/core';
import {of, BehaviorSubject, from} from 'rxjs';
import {Observable} from 'rxjs';
import {first, map, tap} from 'rxjs/operators';

// API
import {ApiService} from '../api/api.service';

@Injectable()
export class EnquiryService {

  collectionName = 'enquiry';

  constructor(
    private apiService: ApiService
  ) {
  }

  /**
   * Adds data to Firestore and returns newly created object
   * @param data - data to add
   * @param collectionName - collection name nested under base path
   * @param basePath - for example `users/12345`
   */
  sendEnquiry(data): Observable<string> {

    // Send to LeadsBasis
    const datasave = {
      'name': data.name,
      'email': data.email,
      'subject': data.subject,
      'message': data.message,
      'notification': 'send',
      'database': 'enquiry',
    };

    return this.apiService.saveDetails(datasave);
  }

}
