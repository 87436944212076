import {NgModule} from '@angular/core';
import {BrowserModule, Title, BrowserTransferStateModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {HttpClientXsrfModule} from '@angular/common/http';
import {environment} from '../environments/environment';

// Tracking
import {Angulartics2Module} from 'angulartics2';
import {Angulartics2GoogleTagManager} from 'angulartics2/gtm';

import {httpInterceptorProviders} from './http-interceptors';
import {HttpErrorHandler} from './http-error-handler.service';
import {PostcodeMatchDirective} from './form/validation/postcode-match.directive';
import {AppRoutingModule} from './app-routing.module';
import {CustomMaterialModule} from './material.module';
import {ShareModule} from '@ngx-share/core';

// Plugins
import {CurrencyMaskModule} from 'ng2-currency-mask'; // https://github.com/cesarrew/ng2-currency-mask
import {MomentModule, DateFormatPipe} from 'ngx-moment';

// Services
import {ApiService} from './api/api.service';
import {EnquiryService} from './services/enquiry.service';

// Modules
import {HomeFeatureModule} from './pages/home/home-feature.module';
import {TemplatesModule} from './templates/template.module';

// General
import {TermsComponent} from './legal/terms/terms.component';
import {PrivacyComponent} from './legal/privacy/privacy.component';

// Error
import {Error404Component} from './pages/error/error404.component';

import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {ServiceWorkerModule} from '@angular/service-worker';

// NgRx
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {metaReducers, reducers} from './state-management';
import {Params, RouterStateSnapshot} from '@angular/router';
import {RoutingHelperService} from './services/routing-helper.service';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {url, root: {queryParams}} = routerState;
    const {params} = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return {url, params, queryParams};
  }
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CustomMaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'Lgc-Xsrf-Cookie',
      headerName: 'Lgc-Xsrf-Header',
    }),
    Angulartics2Module.forRoot(),
    AppRoutingModule,
    CurrencyMaskModule,
    MomentModule,
    ShareModule,
    TemplatesModule,
    HomeFeatureModule,
    SharedModule,
    BrowserTransferStateModule,
    BrowserModule.withServerTransition({appId: 'auswealth-app'}),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, {metaReducers}),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router'
    }),

    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      name: 'Auswealth app',
      logOnly: environment.production,
    }),

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot([
    ]),
  ],
  exports: [
    TermsComponent,
    PrivacyComponent
  ],
  providers: [
    Title,
    httpInterceptorProviders,
    HttpErrorHandler,
    ApiService,
    EnquiryService,
    RoutingHelperService,
    {provide: RouterStateSerializer, useClass: CustomSerializer},
    DateFormatPipe
  ],
  declarations: [
    AppComponent,
    TermsComponent,
    PrivacyComponent,
    Error404Component,
    PostcodeMatchDirective
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    TermsComponent,
    PrivacyComponent
  ]
})

export class AppModule {
}
