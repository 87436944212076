import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeRoutingModule} from './home-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import {CustomMaterialModule} from '../../material.module';
import {CurrencyMaskModule} from 'ng2-currency-mask'; // https://github.com/cesarrew/ng2-currency-mask
import {ShareModule} from '@ngx-share/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

// Templates
import {TemplatesModule} from '../../templates/template.module';
import {SharedModule} from '../../shared/shared.module';

// import the components
import {HomeComponent} from './components/home.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HomeRoutingModule,
    FlexLayoutModule,
    CustomMaterialModule,
    TemplatesModule,
    SharedModule,
    CurrencyMaskModule,
    ShareModule
  ],
  declarations: [
    HomeComponent
  ],
  exports: [
  ],
  providers: [
  ]
})


export class HomeFeatureModule {}
