import {OnInit, Component, OnDestroy, Input, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Animations} from '../tools/animations';
import {Router, ActivatedRoute} from '@angular/router';


// Data handling
import {State, getRedirectState} from '../state-management';
import {select, Store} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {Observable, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SetRedirectDialog} from '../state-management/shared/shared.actions';

@Component({
  selector: 'app-template-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [Animations]
})

export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() brand: string;
  @Input() design: string;
  @Input() showmenu: false;
  @Input() partnerLogo: string;
  @Input() reverseLogos: false;

  // Main Menu object
  mainitems = [
    {
      text: 'Blog',
      type: 'external',
      url: 'https://blog.australiawealth.com.au/'
    },
    {
      text: 'About',
      type: 'internal',
      url: '/about'
    },
    {
      text: 'Contact',
      type: 'internal',
      url: '/contact'
    }
  ];

  loggedin: boolean;
  showAffordability = false;
  // showRisk = false;
  routestatesub = new Subscription();

  authSub: Subscription;

  constructor(
    public dialog: MatDialog,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  logout() {
  }

  ngOnDestroy(): void {
  }
}
