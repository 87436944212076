import {Component, OnInit, Inject, OnDestroy, Renderer2, AfterContentInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DOCUMENT} from '@angular/common';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {Animations, routerTransition} from './tools/animations';
import {Angulartics2GoogleTagManager} from 'angulartics2/gtm';
import {Angulartics2Facebook} from 'angulartics2/facebook';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {State} from './state-management';
import {Store} from '@ngrx/store';
import {SetLastRoute, SetRedirectRoute} from './state-management/shared/shared.actions';
import {TermsComponent} from './legal/terms/terms.component';
import {PrivacyComponent} from './legal/privacy/privacy.component';
import {ApiService} from './api/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [Animations, routerTransition]
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  navSuccess: Observable<NavigationEnd>;
  notice = '';

  constructor(
    private router: Router,
    angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    angulartics2Facebook: Angulartics2Facebook,
    private store: Store<State>,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private apiService: ApiService,
  ) {
    angulartics2GoogleTagManager.startTracking();
    angulartics2Facebook.startTracking();

    this.navSuccess = router.events.pipe(
      filter(evt => evt instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
  }

  ngOnInit() {

    this.navSuccess.subscribe(event => {
      // console.log('Navigation success event', event);
      this.store.dispatch(new SetLastRoute({route: event.urlAfterRedirects}));

      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
    // console.log(this.router.url);
    if (!('ontouchstart' in document.documentElement)) {
      this.renderer.addClass(this.document.body, 'no-touch');
    }

    this.route.queryParams.subscribe(params => {
      if (params['i'] === 'terms') {
        const dialogRef = this.dialog.open(TermsComponent);
      }
      if (params['i'] === 'privacy') {
        const dialogRef = this.dialog.open(PrivacyComponent);
      }
    });

  }

  ngAfterContentInit () {

    setTimeout(() => {
      if (this.route.snapshot.paramMap.get('notice')) {
        this.notice = this.route.snapshot.paramMap.get('notice');
      }
    }, 1000);
    setTimeout(() => {
      this.notice = '';
    }, 6000);

    this.pageView();
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }

  pageView() {
    const data = {
              event: "PageView",
              source: "website",
              // test_id: "TEST17030",
            };
    this.apiService.facebookConversions(data).subscribe();
  }

  ngOnDestroy(): void {
      this.renderer.removeClass(this.document.body, 'embedded-body');
  }

}
