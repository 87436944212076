import {Action} from '@ngrx/store';

export enum SharedActionTypes {
  SetLastRoute = '[Shared] Set last route',
  SetRedirectRoute = '[Shared] Set redirect route',
  SetRedirectDialog = '[Shared] Set redirect dialog',
}

export class SetLastRoute implements Action {
  readonly type = SharedActionTypes.SetLastRoute;

  constructor(public payload: { route: string }) {
  }
}

export class SetRedirectRoute implements Action {
  readonly type = SharedActionTypes.SetRedirectRoute;

  constructor(public payload: { route: string }) {
  }
}

export class SetRedirectDialog implements Action {
  readonly type = SharedActionTypes.SetRedirectDialog;

  constructor(public payload: { route: string }) {
  }
}

export type SharedActionsUnion =
  SetRedirectRoute |
  SetRedirectDialog |
  SetLastRoute;
