import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

/** Match Australian Postcodes */
export function PostcodeMatchValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const postcode = nameRe.test(control.value);
    return !postcode ? {'postcodeMatch': {value: control.value}} : null;
  };
}

@Directive({
  selector: '[appPostcodeMatch]',
  providers: [{provide: NG_VALIDATORS, useExisting: PostcodeMatchDirective, multi: true}]
})
export class PostcodeMatchDirective implements Validator {
  @Input('appPostcodeMatch') postcodeMatch: string;

  validate(control: AbstractControl): {[key: string]: any} {
    return this.postcodeMatch ? PostcodeMatchValidator(new RegExp(this.postcodeMatch, 'i'))(control) : null;
  }
}
