import {Component, OnInit, ElementRef} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Animations} from '../../../tools/animations';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';

import {ApiService} from '../../../api/api.service';
// getjsonData
export interface Options {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-step-home',
  templateUrl: './home.component.html',
  styleUrls: [
    './home.component.css'
  ],
  animations: [Animations]
})

export class HomeComponent implements OnInit {

  blogposts;
  subloader = false;
  subForm = new FormGroup({
    emailFormControl: new FormControl('', [Validators.required, Validators.email])
  });
  users: Observable<any[]>;
  userId: string;
  loggedin = false;
  tools: any;
  // tools = [
  //   {
  //     name: 'veins',
  //     description: 'Veins Consult',
  //     image: 'assets/home/splash-veins.jpg',
  //     link: '/veins'
  //   },
  //   {
  //     name: 'posture',
  //     description: 'Posture Review',
  //     image: 'assets/home/splash-backpain.jpg',
  //     link: '/posture'
  //   },
  //   {
  //     name: 'experience',
  //     description: 'Everybod Contact',
  //     image: 'assets/home/splash-group.jpg',
  //     link: '/contact'
  //   }
  // ];

  slides = [
    // {
    //   id: 0,
    //   src: 'assets/home/homebuilder-grant.jpg',
    //   alt: '',
    //   label: 'HomeBuilder Grant',
    //   title: '$25,000 in grants',
    //   description: 'HomeBuilder is a new government initiative that provides eligible owner-occupiers (including first home buyers) with a grant of $25,000 to build a new home or substantially renovate an existing one.',
    //   tag: 'Popular',
    //   button_text: 'More info',
    //   url: '/homebuilder',
    //   color: '#00567d'
    // },
    {
      id: 0,
      src: 'assets/home/retirement.jpg',
      alt: '',
      label: 'Retirement',
      title: 'The scary truth about retirement',
      description: 'It is not unknown that the Australian population is ageing meaning more and more people are starting to think about retiring. However, is it even possible for Australians to retire now?',
      tag: 'Popular',
      button_text: 'Try now',
      url: '/retirement',
      color: '#00567d'
    },
    {
      id: 1,
      src: 'assets/home/family-home.jpg',
      alt: '',
      label: 'Property Wealth',
      title: 'Creating wealth with property',
      description: `More and more Australians are learning the key strategies to get predictable real wealth building results with property investing. Click 'Learn More' to check how your knowledge stacks up.`,
      tag: 'Popular',
      button_text: 'Learn more',
      url: '/property-mistakes',
      color: '#ffd96a'
    },
    /*
    {
      id: 2,
      src: 'assets/home/just-breathe.jpg',
      alt: '',
      title: 'Relaxation',
      description: 'Discover your way to effortless weight loss, vibrant health, and boundless energy.',
      tag: 'Health Tips',
      button_text: 'Learn more',
      url: '#',
      color: '#ffae87'
    }
    */
  ];
  activeSlide = this.slides[0].id;

  constructor(
    private router: Router,
    private http: HttpClient,
    public myElement: ElementRef,
    private titleService: Title,
    private apiService: ApiService
  ) {
      this.titleService.setTitle('Australia Wealth | Helping all australians on their path to building wealth');
  }

  ngOnInit() {

    // Load Blog Feed
    const blogfeed = 'https://blog.australiawealth.com.au/feed/json';
    this.apiService
      .getjsonData(blogfeed)
      .subscribe(
        data => {
          this.blogposts = data;
        },
        err => {
          // console.log(err);
        }
      );
  }

  scrollTo(selector) {
    const el = this.myElement.nativeElement.querySelector(selector);
    el.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
